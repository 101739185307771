import { CancelToken } from 'axios';
import { AppUser } from '~/models/User/AppUser';
import { AutoLoginAppUserResponse } from '~/models/User/AutoLoginAppUserResponse';
import { CheckUsernameResponse } from '~/models/User/CheckUsername';
import { CreateAppUserResponse, UpsertAppUserRequest } from '~/models/User/CreateAppUser';
import { ResendEmailResponse } from '~/models/User/ResendEmailResponse';
import { AppUserSwitchRoleRequest, AppUserSwitchRoleResponse } from '~/models/User/SwitchRoleAppUser';
import { VerifyAppUserRequest, VerifyAppUserResponse } from '~/models/User/VerifyAppUser';
import { addAddress } from './addresses/add-address';
import { listAddresses } from './addresses/list-addresses';
import { removeAddress } from './addresses/remove-address';
import { syncStates } from './addresses/sync-states';
import { syncStreets } from './addresses/sync-streets';
import { syncSuburbs } from './addresses/sync-suburbs';
import { changeContactPreference } from './change-contact-preference';
import { getProfile } from './get-profile';
import { Api } from '../api';

const accountsApiUrl = 'accounts/users';

export const AccountsApi = {
    getMe: (cancelToken?: CancelToken) => Api.get<AppUser>(`${accountsApiUrl}/me`, { cancelToken }),
    create: (createAppUserRequest: UpsertAppUserRequest, cancelToken?: CancelToken) => Api.post<CreateAppUserResponse>(accountsApiUrl, createAppUserRequest, { cancelToken }),
    // Sync itk user into itg.
    sync: (cancelToken?: CancelToken) => Api.post<void>(`${accountsApiUrl}/sync`, {}, { cancelToken }),
    update: (updateAppUserRequest: AppUser, cancelToken?: CancelToken) => Api.put<AppUser>(accountsApiUrl, updateAppUserRequest, { cancelToken }),
    // Exchange the User's IDP-ApiKey for its credentials (including access token).
    exchangeApiKey: (userId: number, cancelToken?: CancelToken) => Api.post<AutoLoginAppUserResponse>(`${accountsApiUrl}/${userId}/exchange-apikey`, userId, {cancelToken}),
    verify: (veryfyAppUserRequest: VerifyAppUserRequest, cancelToken?: CancelToken) => Api.post<VerifyAppUserResponse>(`${accountsApiUrl}/${veryfyAppUserRequest.userId}/verify`, veryfyAppUserRequest, { cancelToken }),
    resendEmail: (cancelToken?: CancelToken) => Api.post<ResendEmailResponse>(`${accountsApiUrl}/resend-email`, { cancelToken }),
    checkUsername: (email: string, cancelToken?: CancelToken) => Api.get<CheckUsernameResponse>(`${accountsApiUrl}/check-username`, { params: { userEmail: email }, cancelToken }),
    switchUserRole: (userId: number, switchUserRoleRequest: AppUserSwitchRoleRequest, cancelToken?: CancelToken) => Api.post<AppUserSwitchRoleResponse>(`${accountsApiUrl}/${userId}/switch-role`, switchUserRoleRequest, { cancelToken}),
    changeContactPreference,
    // Profile.
    getProfile,
    // Addresses.
    addresses: {
        addAddress,
        listAddresses,
        removeAddress,
        syncStates,
        syncSuburbs,
        syncStreets
    }
};