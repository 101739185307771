import App from 'next/app';
import Head from 'next/head';
import { PageProps } from '~/models/App/PageProps';
import { AppStore } from '~/stores/AppStore';
import { initialiseAppStore, StoreProvider } from '~/stores/stores';
import Axios, { CancelTokenSource } from 'axios';
import { logger } from '@infotrack/infotrackgo.web.core/framework/logging/logger';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '~/components/MuiTheme/theme';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { initialiseLogger } from '@infotrack/infotrackgo.web.core/framework/logging/frontEndLogger';
import { AppCtx } from '~/framework/types/app-ctx';
import createEmotionCache from '~/framework/caching/createEmotionCache';
import { getQueryParameters } from '~/framework/startup/getQueryParamaters';
import { upsertTrackingInfo } from '~/framework/startup/upsertTrackingInfo';
import { ErrorBoundary } from '@infotrack/infotrackgo.web.core/components';
import { Setting, Settings } from '~/framework/setting';
import { userOnStartup } from '~/framework/statup/userOnStatup';
import { initialiseRum } from '~/framework/rum/datadogRum';

// This includes our lean global styles with each page.
import '@infotrack/infotrackgo.web.core/styles/globals.scss';
import '@infotrack/infotrackgo.web.core/styles/typography.scss';


// This file overides the custom <App></App> component to intialize pages.
// Its a custom component used to render mobx store through app context.
// https://nextjs.org/docs/#custom-app

interface Props extends PageProps {
    appStore: AppStore;
    absoluteUrl: string;
    emotionCache?: EmotionCache;
}

let appStore: AppStore;

const clientSideEmotionCache = createEmotionCache();

class InfotrackGo extends App<Props> {
    props: Props & AppCtx;
    appStore: AppStore;
    cancelTokenSrc: CancelTokenSource;

   /**
    * This is only called Server Side!
    */
    static async getInitialProps(appContext: any) {
        const settings = new Setting();
        const queryData = getQueryParameters(appContext.ctx);
        const appStore = initialiseAppStore(queryData);
        // Provide the store to getInitialProps of pages
        appContext.ctx.appStore = appStore;
        let appProps = await App.getInitialProps(appContext);
        // Used for SEO Purposes for now.
        let host = typeof(window) === 'undefined' ? appContext.ctx.req.headers['host'] : window.location.host;
        let path = typeof(window) === 'undefined' ? appContext.ctx.asPath : window.location.pathname;
        const absoluteUrl = `${host}${path}`;

        return {
            absoluteUrl,
            ...appProps,
            appStore,
            // Carry the browser env variables to the browser...
            settings
        };
    }

    constructor(props: any) {
        super(props);
        // Load the client environment variables from server context...
        Settings.settings = props.settings;
        // Pass the hydrated mobxStore to the initialise so we can create a new store
        this.appStore = initialiseAppStore(props.appStore);
        appStore = this.appStore;
    }

    componentDidMount() {
        initialiseLogger(Settings.settings.DatadogConfig);
        initialiseRum(Settings.settings.DatadogRumConfig);
        
        // Upsert any google tracking information into local storage.
        // if it is already there and still valid, leave it alone.
        upsertTrackingInfo(this.appStore, this.appStore.trackingInformation);

        // Startup datadog logging
        logger.info(`Loaded page: ${window.location.pathname}`);

        // Load any user related info when logged in
        this.cancelTokenSrc = Axios.CancelToken.source();
        userOnStartup(this.appStore.userStore, this.cancelTokenSrc.token);
    }

    componentWillUnmount() {
        this.cancelTokenSrc.cancel();
        this.cancelTokenSrc = Axios.CancelToken.source();
    }

    render() {
        const { Component, pageProps, emotionCache = clientSideEmotionCache } = this.props;

        return (
            <>
                <CacheProvider value={emotionCache}>
                    <ThemeProvider theme={theme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline enableColorScheme />
                        <StoreProvider value={this.appStore}>
                            <Head>
                                <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
                                <meta name="theme-color" content="#ffa500" key="theme-color" />
                                <meta name="trustpilot-one-time-domain-verification-id" content="501e21e2-a716-4c09-87a4-f897ce5383bd" />
                                {/* Start recaptcha */}
                                <script src={`https://www.google.com/recaptcha/api.js?render=${Settings.settings.GoogleRecaptchaSiteKey}`}></script>
                                {/* End recaptcha */}
                                {/* Start Google Tag Manager */}
                                <script
                                    id="gtag-script"
                                    dangerouslySetInnerHTML={{
                                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','GTM-ND6V766');`
                                    }}
                                />
                                {/* End Google Tag Manager */}
                                {/* Start Google Analitics - G4 (gtag.js)*/}
                                <script
                                    async
                                    src={`https://www.googletagmanager.com/gtag/js?id=${Settings.settings.GoogleAnalyticsMeasurementID}`}
                                />
                                <script
                                        id="google-analytics"
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){dataLayer.push(arguments);}
                                            gtag('js', new Date());
                                            gtag('config', '${Settings.settings.GoogleAnalyticsMeasurementID}');
                                        `
                                    }}
                                />
                                {/* End Google Analitics - G4 (gtag.js)*/}
                                {/* Start google optimize tag */}
                                <link rel="preconnect" as="script" href="https://www.googleoptimize.com/optimize.js?id=OPT-MBJKQF9" />
                                {/* End google optimize tag */}
                                {/* Download google fonts */}
                                <link
                                    rel="stylesheet"
                                    href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=optional"
                                />
                                <link rel="shortcut icon" type="image/x-icon" href="https://d30tso943ai3px.cloudfront.net/itg/icons/favicon.svg" />
                            </Head>
                            {/* Google Tag Manager (noscript) */}
                            <noscript>
                                <iframe
                                    src="https://www.googletagmanager.com/ns.html?id=GTM-ND6V766"
                                    height="0"
                                    width="0"
                                    style={{ display: 'none', visibility: 'hidden' }}
                                />
                            </noscript>
                            {/* End Google Tag Manager (noscript) */}
                            <ErrorBoundary>
                                <Component {...pageProps} />
                            </ErrorBoundary>
                        </StoreProvider>
                    </ThemeProvider>
                </CacheProvider>
            </>
        );
    }
}

export default InfotrackGo;

export const getMobxStores = () => {
  return appStore;
};
