import { CoreApi, logger } from '@infotrack/infotrackgo.web.core/framework';
import { InternalAxiosRequestConfig } from 'axios';
import { Auth } from '~/framework/auth/auth';
import Cookie from 'js-cookie';
import { Constants } from '~/framework/constants/constants';
import { refreshLoggingContext } from '~/framework/constants/loggingContexts';

export const Api = (() => {
    const core = CoreApi;
    // Use axios interceptors to add the user's authorization token when it is available.
    core.axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        const url: string = config.url ?? '';
        // Skip interception logic if this is not a call to itg.
        if (!url.includes('search.infotrackgo.com.au/api')) return config;

        const isGuest = Auth.getIsGuest();
        const hasRefreshToken = !!Auth.getRefreshToken();
        let accessToken = Auth.getAccessToken();

        if (accessToken) {
            // If token has expired, try to refresh before requesting.
            const isAuthenticated = Auth.isAuthenticated();

            if (
                !isAuthenticated
                && !isGuest
                && hasRefreshToken
            ) {
                logger.info('Access token no longer valid, attempting token refresh before API call', refreshLoggingContext)
                accessToken = await Auth.tryRefresh('expired');
            }
            if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    });

    return core;
})();