import { Constants } from '@infotrack/infotrackgo.web.core/framework/constants/constants';
import { localStorageManager } from '@infotrack/infotrackgo.web.core/framework/utils/localStorageFormManager';

/**
 * Aims to set the different modes encountered on Components that encapsulates the signup (Contact cetails step, Signup page).
 * 1. Form input
 * 2. Loading
 * 3. Read (display a card with the input)
 * 4. When creating the user, saves the state to display de code validation form
 * 5. Account creation complete (signup page)
 */
export type SignupMode = 'input' | 'loading' | 'verification' | 'complete';

/**
 * Helper function to retrieve the original SignupMode value from local storage
 */
export const getSignUpModeFromLocalStorage = (isLoggedIn: boolean, isVerified?: boolean): SignupMode => {
    let lsMode = localStorageManager<SignupMode>(Constants.LOCAL_STORAGE.SIGNUP_MODE)?.get();
    if (lsMode) return lsMode;

    lsMode = isLoggedIn
        ? isVerified ? 'complete' : 'verification'
        : 'input';
    localStorageManager<SignupMode>(Constants.LOCAL_STORAGE.SIGNUP_MODE)?.set(lsMode);
    return lsMode;
};

/**
 * Sync signupMode on local storage
 */
export const setSignUpModeOnLocalStorage = (mode: SignupMode): void => {
    localStorageManager<SignupMode>(Constants.LOCAL_STORAGE.SIGNUP_MODE)?.set(mode);
};

/**
 * Reset signupMode from local storage
 */
export const resetSignupModeOnLocalStorage = () => {
    localStorageManager<SignupMode>(Constants.LOCAL_STORAGE.SIGNUP_MODE)?.reset();
};